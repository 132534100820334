import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '',
    redirect: { name: 'Login' },
  },

  {
    path: '/users',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/layout/PublicView.vue'
      ),
    children: [
      {
        path: 'login/:r?',
        props: (route) => ({
          r: route.params.r || '',
        }),
        name: 'Login',
        component: () =>
          import(
            /* webpackChunkName: "UsersLogin" */ '@/views/users/LoginView.vue'
          ),
      },
      {
        path: 'password-recovery',
        name: 'PasswordRecovery',
        component: () =>
          import(
            /* webpackChunkName: "UsersPasswordRecovery" */ '@/views/users/PasswordRecoveryView.vue'
          ),
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/layout/PublicView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  store.dispatch('screen/setLoadingState', true)
  next()
  // if (to.matched.some((record) => record.meta.authorizationRequired)) {
  //   if (store.getters['session/getAuthStatus']) {
  //     next()
  //     return
  //   }
  //   next('/users/login')
  // } else {
  //   next()
  // }
})

router.afterEach(() => {
  store.dispatch('screen/setLoadingState', false)
})

export default router
