<template>
  <router-view />
  <g-dialog-root />
</template>

<script>
import { GDialogRoot } from 'gitart-vue-dialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    GDialogRoot,
  },
  computed: {
    ...mapGetters({
      getClientSizeW: 'screen/getClientSizeW',
      getScrollState: 'screen/getScrollState',
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },

  async created() {
    await this.$store.dispatch('session/recoverSession', '')
  },

  methods: {
    ...mapActions({
      setPos: 'screen/setPos',
      setScrollState: 'screen/setScrollState',
      setClientSize: 'screen/setClientSize',
      setMiniModeState: 'screen/setMiniModeState',
    }),
    handleScroll(e) {
      let pos = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      this.setPos(pos)
      this.setScrollState(pos > 45)
      this.setMiniModeState(this.getClientSizeW < 992)
    },
    handleResize() {
      this.setClientSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      })
    },
  },
}
</script>

<style></style>
