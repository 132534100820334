const state = () => ({
  pos: 0,
  scrollState: false,
  miniModeState: false,
  loadingState: false,
  clientSize: { width: 0, height: 0 },
})

const actions = {
  /* Window */

  setPos({ commit }, pos) {
    commit('SET_POS', pos)
  },

  setClientSize({ commit }, clientSize) {
    commit('SET_CLIENT_SIZE', clientSize)
  },

  setScrollState({ commit }, status) {
    commit('SET_SCROLL_STATE', status)
  },

  setMiniModeState({ commit }, status) {
    commit('SET_MINI_MODE_STATE', status)
  },

  setLoadingState({ commit }, status) {
    commit('SET_LOADING_STATE', status)
  },
}

const mutations = {
  /* Window */

  SET_CLIENT_SIZE(state, clientSize) {
    state.clientSize.width = clientSize.width
    state.clientSize.height = clientSize.height
  },

  SET_POS(state, pos) {
    state.pos = pos
  },

  SET_SCROLL_STATE(state, status) {
    state.scrollState = status
  },

  SET_MINI_MODE_STATE(state, status) {
    state.miniModeState = status
  },

  SET_LOADING_STATE(state, status) {
    state.loadingState = status
  },
}

const getters = {
  getClientSize: (state) => {
    return state.clientSize
  },
  getPos: (state) => {
    return state.pos
  },
  getClientSizeW: (state) => {
    return state.clientSize.width
  },
  getClientSizeH: (state) => {
    return state.clientSize.height
  },
  getScrollState: (state) => {
    return state.scrollState
  },
  getMiniModeState: (state) => {
    return state.miniModeState
  },
  getLoadingState: (state) => {
    return state.loadingState
  },
  getState: (state) => {
    return state
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
